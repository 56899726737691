<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import useNetw from "@useNetw";
import Swal from "sweetalert2";
// import btnComponent from "@/components/btnComponent";
import Multiselect from "vue-multiselect";
import DatePicker from "vue2-datepicker";
// import branchComponent from "@/components/branchComponent.vue";
// import { tableData } from "./dataAdvancedtable";

/**
 * Advanced-table component
 */
export default {
  page: {
    title: "เพิ่มข้อมูลสั่งซื้อรถเข้าสต็อก",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: { Layout, PageHeader, Multiselect, DatePicker },
  data() {
    return {
      // tableData: tableData,

      title: "เพิ่มข้อมูลสั่งซื้อรถเข้าสต็อก",
      items: [
        {
          text: "คลังสินค้า",
          active: true,
        },
        {
          text: "สั่งซื้อรถเข้าสต็อก",
          active: false,
          href: "/vehicle-po",
        },
        {
          text: "เพิ่มข้อมูลสั่งซื้อรถเข้าสต็อก",
          active: true,
        },
      ],
      rowsSelect: [],
      chk: "",
      selected: [],
      rowsTotalpages: "",
      rowsperPage: "",
      rowsPage: "",
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      arrTest: [],
      totalRecords: 0,
      pageOptions: [10, 25, 50, 100],
      optionBranch: [],
      optionStatus: [
        {
          name: "ยืนยันแล้ว",
          status: "APV",
        },
        {
          name: "ยกเลิกแล้ว",
          status: "CAN",
        },
      ],
      poDate: "",
      supId: "",
      userBranchId: "",

      vatPer: "",

      userBranch: "",
      filterOn: [],
      rowsPO: [],
      rowsSuppliers: [],
      sortBy: "age",
      sortDesc: false,
      fields: [
        {
          key: "index",
          label: "ลำดับ",
          sortable: false,
        },
        {
          key: "poCode",
          sortable: true,
          label: "รหัสสั่งซื้อ",
        },
        {
          key: "supplierNameTh",
          sortable: true,
          label: "ผู้ผลิต",
        },
        {
          key: "poDate",
          sortable: true,
          label: "วันที่สั่งซื้อ",
        },
        {
          key: "grandTotalPrice",
          sortable: true,
          label: "ราคารวม",
        },
        {
          key: "branchName",
          sortable: true,
          label: "สาขา",
        },
        {
          key: "status",
        },
        {
          key: "action",
          label: appConfig.action,
          sortable: false,
        },
      ],
      overlayFlag: false,
    };
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.rowsPO.length;
    },
  },
  mounted() {
    const user = JSON.parse(localStorage.getItem(appConfig.storageUserKey));
    const arrayBranch = [];
    const arrayBranchAll = [];
    user.userAccessBranch.forEach((item) => {
      arrayBranch.push(item.branchId);
      arrayBranchAll.push(item);
    });
    this.userBranch = arrayBranch;
    this.optionBranch = arrayBranchAll;
    this.totalRows = this.rowsPO.total;
    // this.getDataSuppliers();
  },
  created() {
    // this.getData();
    // this.select()
  },
  methods: {
    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      // this.currentPage = 1;
    },
    getDataSuppliers: function() {
      this.overlayFlag = true;
      useNetw
        .get("api/master/suppliers", {
          params: {
            branchId:
              this.userBranchId != null
                ? this.userBranchId.branchId
                : undefined,
          },
        })
        .then((response) => {
          this.rowsSuppliers = response.data.data;
          this.rowsSuppliers.to = response.data.to;
          this.rowsSuppliers.from = response.data.from;
          this.rowsSuppliers.total = response.data.total;
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.message),
            appConfig.swal.type.error
          );
          // this.$router.push("/login");
        })
        .then(() => {
          this.overlayFlag = false;
        });
    },
    submitVehiclePO() {
      this.overlayFlag = true;
      useNetw
        .post("api/vehicle-po/store", {
          branchId:
            this.userBranchId != null ? this.userBranchId.branchId : undefined,
          supId: this.supId != null ? this.supId.supId : undefined,
          poDate: this.poDate,
          vatPer: this.vatPer,
        })
        .then((response) => {
          Swal.fire(
            appConfig.swal.title.postSuccess,
            JSON.stringify(response.data.message),
            appConfig.swal.type.success
          );
          this.$router.push({
            name: "detail-vehicle-po",
            params: { poId: btoa(response.data.poId) },
          });
          //   this.getData();
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(
              err.response.data.message +
                "<br/>" +
                err.response.data.validationMessage
            ),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.overlayFlag = false;
        });
    },
    updateVehiclePO() {
      this.overlayFlag = true;
      useNetw
        .post("api/vehicle-po/update", {
          poId:
            this.userBranchId != null ? this.userBranchId.branchId : undefined,
          supId: this.supId != null ? this.supId.supId : undefined,
          poDate: this.poDate,
          totalPrice: this.totalPrice,
          dcPer: this.dcPer,
          dcPrice: this.dcPrice,
          netPrice: this.netPrice,
          vatPer: this.vatPer,
          vatPrice: this.vatPrice,
          grandTotalPrice: this.grandTotalPrice,
        })
        .then((response) => {
          Swal.fire(
            appConfig.swal.title.postSuccess,
            JSON.stringify(response.data.message),
            appConfig.swal.type.success
          );
          this.$router.push({ name: "vehicle-po" });
          //   this.getData();
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.overlayFlag = false;
        });
    },
    // deleteVehicle(rowsPO) {
    //   this.vehicleId = rowsPO;
    //   this.overlayFlag = true;
    //   useNetw
    //     .delete("api/vehicle/delete", {
    //       data: {
    //         vehicleId: this.vehicleId,
    //       },
    //     })
    //     .then((response) => {
    //       Swal.fire(
    //         appConfig.swal.title.deleterSuccess,
    //         JSON.stringify(response.data.message),
    //         appConfig.swal.type.success
    //       );
    //     //   this.getData();
    //     })
    //     .catch((err) => {
    //       Swal.fire(
    //         appConfig.swal.title.error,
    //         JSON.stringify(err.message),
    //         appConfig.swal.type.error
    //       );
    //     })
    //     .then(() => {
    //       this.overlayFlag = false;
    //     });
    // },
    // alertDelete(vehicleId) {
    //   const swalWithBootstrapButtons = Swal.mixin({
    //     customClass: {
    //       confirmButton: "btn btn-success",
    //       cancelButton: "btn btn-danger ms-2",
    //     },
    //     buttonsStyling: false,
    //   });

    //   swalWithBootstrapButtons
    //     .fire({
    //       title: "ต้องการลบหรือไม่?",
    //       text: "ต้องการ Vehicle นี้หรือไม่ !!",
    //       icon: "warning",
    //       confirmButtonText: "OK",
    //       cancelButtonText: "Cancel!",
    //       showCancelButton: true,
    //     })
    //     .then((result) => {
    //       if (result.value) {
    //         this.deleteVehicle(vehicleId);
    //       } else if (
    //         /* Read more about handling dismissals below */
    //         result.dismiss === Swal.DismissReason.cancel
    //       ) {
    //         swalWithBootstrapButtons.fire(
    //           "Cancelled",
    //           "ยกเลิกเรียบร้อยแล้ว",
    //           "error"
    //         );
    //       }
    //     });
    // },
  },
  middleware: "authentication",
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <b-overlay
          :show="overlayFlag"
          :spinner-variant="this.$overlayVariant"
          :spinner-type="this.$overlayType"
          :rounded="this.$overlayRounded"
        >
          <div class="card">
            <div class="card-body">
              <!-- <h4 class="card-title">Vehicle List</h4> -->
              <div class="row">
                <div class="col-sm-12 col-md-2"></div>
              </div>
              <!-- Search -->
              <div class="row justify-content-md-center">
                <div class="col-sm-12 col-md-12">
                  <div class="row">
                    <div class="col-12 col-sm-12 col-md-12">
                      <div class="row">
                        <div class="col-6 col-sm-6 col-md-4">
                          <div class="mb-3 position-relative">
                            <code> * </code>สาขา:
                            <multiselect
                              v-model="userBranchId"
                              label="nameTh"
                              type="search"
                              :options="optionBranch"
                              :show-labels="false"
                              open-direction="bottom"
                              placeholder=""
                              @input="getDataSuppliers"
                            >
                              <span slot="noResult">ไม่พบข้อมูล</span>
                            </multiselect>
                          </div>
                        </div>
                        <div class="col-6 col-sm-6 col-md-4">
                          <div class="mb-3 position-relative">
                            <code> * </code>ผู้ผลิต:
                            <multiselect
                              v-model="supId"
                              label="nameTh"
                              type="search"
                              :options="rowsSuppliers"
                              :show-labels="false"
                              open-direction="bottom"
                              placeholder=""
                            >
                              <span slot="noResult">ไม่พบข้อมูล</span>
                            </multiselect>
                          </div>
                        </div>
                        <div class="col-6 col-sm-6 col-md-4">
                          <div class="mb-3 position-relative">
                            <code> * </code>วันที่สั่งซื้อ:
                            <date-picker
                              v-model="poDate"
                              format="YYYY-MM-DD"
                              value-type="format"
                            ></date-picker>
                          </div>
                        </div>
                        <div class="col-6 col-sm-6 col-md-4">
                          <div class="mb-3 position-relative">
                            ภาษี:
                            <b-form-input
                              v-model="vatPer"
                              type="search"
                              class="form-control"
                            ></b-form-input>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-12 col-sm-12 col-md-12 text-end">
                            <b-button
                              v-if="!this.$route.params.poId"
                              class="btn m-2"
                              variant="primary"
                              type="submit"
                              @click="submitVehiclePO"
                            >
                              <!-- <i class="uil-plus"></i> -->
                              บันทึก
                            </b-button>
                            <b-button
                              v-if="this.$route.params.poId"
                              class="btn m-2"
                              variant="primary"
                              type="submit"
                              @click="updateVehiclePO"
                            >
                              <!-- <i class="uil-plus"></i> -->
                              บันทึกการแก้ไขข้อมูล
                            </b-button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-overlay>
      </div>
    </div>
  </Layout>
</template>
